//- Mixines
@import "../abstracts/mixins"

// Color Icons
[class^="cIco-"], [class*=" cIco-"]
	&:before
		content: ''
		width: 60px
		height: 60px
		display: block
		background-position: center center
		background-repeat: no-repeat
		background-size: contain

